@import "../../variables.scss";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

.navMain {
    background-color: $primary-color;
    border-bottom: 2px solid rgba(255, 255, 255, 0);
    height: 64px;
    position: relative;
    z-index: 1000;

}

.burgerIcon {
    cursor: pointer;
    height: 22px;
    position: relative;
    top: 2px;
    width: 25px;
    
    span {
        background-image: url(../../images/misc/burger-texture-01.png);
        background-size: 60px 3px;
        border-radius: $base-spacing;
        display: block;
        height: 3px;
        position: absolute;
        top: 0;
        transform: rotate(0deg) translate(0px);
        transition: 0.4s ease-in-out;
        width: 25px;
    }

    span:nth-child(2) {
        background-position-x: 33%;
        top: 9px;
    }

    span:nth-child(3) {
        background-position-x: 66%;
        top: 18px;
    }

    span.navCross:nth-child(1) {
        height: 3px;
        top: 7.5px;
        transform: rotate(135deg);
    }

    span.navCross:nth-child(2) {
        opacity: 0;
        transform: translateX(-100%);
    }

    span.navCross:nth-child(3) {
        height: 3px;
        top: 7.5px;
        transform: rotate(-135deg);
    }
}

.navBrandText {
    color: white;
    font-family: 'Fredericka the Great', cursive;
    font-size: 1.5rem;
    margin-right: 1rem;
    padding-bottom: 0.3125rem;
    padding-top: 0.3125rem;
    text-decoration: none;
    text-shadow: 0px 0px 0px rgb(180, 180, 180);
    white-space: nowrap;
}

.navOverlay {
    @include nav-t-duration;
    background-color: $bg-color;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: $base-spacing * 8;
    transition-property: height;
    width: 100vw;
}

.navBarLinkGroup {
    display: none;
    position: relative;
}

.enter {        
    display: block;
    transform: translateY(-100%);
}

.enterActive {
    @include nav-t-duration;
    opacity: 1;
    transform: translateY(0);
}

.enterDone {
    display: block;
}

.exit {
    @include nav-t-duration;
    display: block;
    opacity: 1;
    transform: translateY(0);
}

.exitActive {
    display: block;
    transform: translateY(-100%);
}

.exitDone {
    display: none;
}

@include media-breakpoint-up(sm) {
    .navBrandText {
        font-size: 2rem;
    }
}

@include media-breakpoint-up(md) {
    .navMain {
        border: unset;

    }

    .burgerIcon {
        display: none;
    }

    .enter,
    .exitActive {
        transform: translate(0);
    }
    
    .navBarLinkGroup {
        display: flex;
        justify-content: flex-end;
        position: unset;            
    }

    .navBrandText {
        font-size: 1.75rem;
        padding-bottom: 0.325rem;
        padding-top: 0;
    }
    
    .navOverlay {
        background: none;
        position: static;
    }
}

@include media-breakpoint-up(lg) {
    .navBrandText {
        font-size: 2rem;
        padding-bottom: 0.3125rem;
        padding-top: 0.3125rem;
    }
}