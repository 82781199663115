.aboutSection {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.gridImgCont {
    display: grid;
    gap: 1rem 0rem;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 2rem;

    img {
        object-fit: contain;
        margin-bottom: 1rem;
        width: 100%;
    }
}

.galleryCont * {
    font-weight: bold;
}

.galleryCont {
    height: 80vh;

    ul {
        font-size: 18px;
    }
    
    div {
        span {
            border-radius: 0.25rem;
        }
    }

    figcaption {
        border-radius: 0.25rem;
        font-weight: bold;
        overflow: hidden;
        margin-bottom: 0.5rem;
    }
}

@media only screen and (min-width: 992px) {
    .gridImgCont {
        gap: 1rem 1.5rem;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 1536px) {
    .gridImgCont {
        gap: 1rem 1.5rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}