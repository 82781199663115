.backArrowCont {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    margin-top: 4rem;
    transform: translateX(0px);
    transition: transform 0.4s;
    width: 50px;

    &:hover {
        transform: translateX(-15px);
    }

    p {
        font-size: 16px;
        height: 25px;
        line-height: 25px;
        margin: 0 0.5rem;
    }
}

.backArrow {
    height: 25px;
    font-size: 24px;
    text-align: left;
}

.backBorder {
    content: "";
    display: inline-block;
    height: 25px;
    min-width: 65px;
    position: absolute;
    transform: translateX(0px);
    transition: 0.4s;
}

.backBorderDark {
    border-bottom: solid 1px rgba(0, 0, 0, 0.534);
    border-top: solid 1px rgba(0, 0, 0, 0.534);
}

.backBorderLight {
    border-bottom: solid 1px rgba(255, 255, 255, 0.534);
    border-top: solid 1px rgba(255, 255, 255, 0.534);
}

.backHoverDark {
    border-color: black;
    transform: translateX(20px);
}

.backHoverLight {
    border-color: white;
    transform: translateX(20px);
}