@import "../../variables.scss";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

.heroCont.homeStyle {
    height: 100%;
    padding: 3rem 0;
}

.heroCont {
    color: white;
    flex-grow: 1;
    overflow: hidden;
    padding: 2rem 0;
    position: relative;
       
    .heroText {
        position: relative;
        z-index: 2;

        .typistCont {
            position: relative;

            .hiddenText {
                opacity: 0;
            }

            .typistEl {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
            }
        }

        h4, h5 {
            margin-top: 1rem;
            opacity: 0;
            transform: translateY(10%);
            transition: transform 1.5s, opacity 1.5s;
        }

        .fadeIn {
            opacity: 1;
            transition-delay: 1.5s;
            transform: translateY(0);
        }

        h5.fadeIn {
            transition-delay: 3.5s;
        }

        .staticTxt {
            opacity: 1;
            transform: translateY(0);
        }

        h1 {
            font-size: 1.625rem;
            display: inline;
        }

        h1 + span {
            bottom: 2px;
            font-size: 1rem;
            padding-bottom: 4px;
            padding-left: 2px;
            position: relative;
        }

        h4 {
            font-size: 1.25rem;
        }

        h5.smallText {
            font-size: 0.875rem;
            font-weight: normal;
        }

        h5 {
            font-size: 1.125rem;
        }
    }
}

@include media-breakpoint-up(sm) {
    .heroCont {
        .heroText {
            h1 {
                font-size: 2rem;
            }

            h1 + span {
                bottom: 3px;
                font-size: 1.25rem;
            }

            h4 {
                font-size: 1.375rem;
            }
        }
    }
}
  
  @include media-breakpoint-up(md) {
    .heroCont.homeStyle {
        padding: 4rem 0;
    }

    .heroCont {
        .heroText {
            h1 {
                font-size: 2.5rem;
            }

            h1 + span {
                font-size: 1.5rem;
            }

            h4 {
                font-size: 1.75rem;
            }

            h5 {
                font-size: 1.375rem;
            }
        }
    }
  }