.article {
    height: 100%;
}

.articleContent {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.articleHead {
    h4 {
        font-weight: bold;
    }

    h5 {
        margin-bottom: 1rem;
    }
}