@import "../../variables.scss";

.infoSection {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.contactSection {
    margin-bottom: 4rem;
}

.infoCont {
    h3 {
        margin-bottom: 2rem;
    }
}


.plotArea {
    height: 200px;
    margin-bottom: 0.5rem;
    overflow: hidden;
    width: 100%;
}

.grassCont {
    border-radius: 0.25rem;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

}

.grassTexture {
    position: absolute;
    width: 100%;
    z-index: -1;
}

.plotBot {
    bottom: 87.5%;
}

.plotTop {
    bottom: 92.5%;
}

.plotCont {
    border-radius: 0.25rem;
    display: flex;
    height: 40%;
    left: 5%;
    overflow-x: hidden;
    position: relative;
    width: 90%;
}

.plotMin {
    transform: translateX(-50%);
}

.plotSm {
    transform: translateX(0px);
}

.plotStandard {
    transform: translateX(-101%);
}

.plotStandardIn {
    transform: translateX(0px);
}

.plotBackground {
    background: url("../../images/main/info/info-dirt-texture.jpg") repeat;
    flex-grow: 1;
    transition: 1s;
}

.plotBtn {
    border-radius: 0.25rem;
    background-color: $bg-color;
    box-shadow: none;
    margin-left: 0 !important;
    text-align: center;
    transition: 0.3s, box-shadow 0.2s;
    width: 110px;
    
    &:hover {
        box-shadow: 0 2px 7px -3px #a2a2a299;
    }
    
    &.btnActive {
        background-color: $primary-light;
        box-shadow: 0 2px 7px -1px #a2a2a2dd;
        color: white;
    }
}

.plotSizeBtn {
    &:focus-visible, &:active, &:focus, &.btnActive {
        transform: scale(1.1);
    }
}

.plotDetails {
    margin-bottom: 3rem;
}

.plotButtons {
    margin-bottom: 1.5rem;
}

.plotBtn {
    border-radius: 0.25rem;
    text-align: center;
    width: 110px;
}

.info-item-text {
    font-size: 0.9rem;
}

.plot-car {
    object-fit: fill;
    width: 25%;
    height: 20%;
}

.googleMap {
    border: none;
    border-radius: 0.25rem;
    display: block;
    margin: 2rem auto;
    width: 100%;
    height: 300px;
}

@media only screen and (min-width: 576px) {
    .googleMap {
        height: 400px;
    }
}

@media only screen and (min-width: 768px) {
    .googleMap {
    height: 450px;
    }
}