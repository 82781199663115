$primary-color: #0A2004;
$primary-light: #396F04;
$secondary-color: #E28803;
$secondary-color-light: #DEA606;
$bg-color: #F3F5F2;
$light-border-color: #e6e6e6;
$mid-border-color: #a2a2a2;
$dark-border-color: #616161;
$base-spacing: 8px;
$loader-h: 80px;
$loader-w: 40px;

/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
)*/

@mixin nav-t-duration {
    transition-duration: 0.5s;
}